<template>
    <div id="Qcode" class="q-pa-md q-gutter-sm">
        <!-- 面包屑 -->
        <q-breadcrumbs>
            <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
            <q-breadcrumbs-el label="订单管理" icon="iconfont icon-dingdanguanli" />
            <q-breadcrumbs-el label="二维码管理" icon="iconfont icon-erweima" to="/Qcode" />
        </q-breadcrumbs>

        <div class='conditionBox'>
            <q-card class="my-card" id="defaultCondition">
                <q-card-section class='defaultBox'>
                    <div class='tit'>
                        默认值设置
                    </div>
                    <div class="main">
                        <!-- 省份选择 -->
                        <selectp @provincechange='provincechange' class='add' :options='defaultData.province' :model.sync='defaultData.provinceId' :label='"请选择省份*"' :rulesDisable='true'/>
                        <!-- 城市选择 -->
                        <selectc @citychange='citychange' class='add' :options='defaultData.city' :model.sync='defaultData.cityId' :label='"请选择城市*"' :rulesDisable='true'/>
                        <!-- 医院选择 -->
                        <selecth @hospchange='hospchange' class='add' :options='defaultData.hosp' :model.sync='defaultData.hospId' :label='"请选择医院*"' :rulesDisable='true'/>
                        <!-- 科室选择 -->
                        <selectd class='add' :options='defaultData.depart' :model.sync='defaultData.departId' :label='"请选择科室*"' :rulesDisable='true'/>
                        <!-- 套餐选择 -->
                        <comboselect class='add' :options='defaultData.combo' :model.sync='defaultData.comboId' :label='"请选择套餐*"' :rulesDisable='true'/>

                    </div>
                </q-card-section>

                <q-card-section class='optionalBox'>
                    <div class='tit'>
                        可选 
                        <el-checkbox style="margin-left: 10px;" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    </div>
                    <div class="main">
                        <el-checkbox v-model="defaultData.attendingDoctor" label="主管医师" @change='checkboxChange'></el-checkbox>
                        <el-checkbox v-model="defaultData.surgeryDate" label="手术时间" @change='checkboxChange'></el-checkbox>
                        <el-checkbox v-model="defaultData.hospNumber" label="住院号" @change='checkboxChange'></el-checkbox>
                        <el-checkbox v-model="defaultData.discountCode" label="优惠码" @change='checkboxChange'></el-checkbox>
                    </div>
                </q-card-section>

                <q-card-section class='attribute'>
                    <div class='tit'>
                        二维码属性 <span class="Tips">(默认宽高230*230,最大宽高500*500)</span>
                    </div>
                    <div class="main">
                        <!-- <div class='box'> -->
                        <div class='mainBox'>
                            <div class='widthHeight'>
                                <div class='widthHeightTit'>宽度</div>
                                <q-input outlined v-model="defaultData.width" :dense="true" label="宽度" />
                            </div>

                            <div class='widthHeight'>
                                <div class='widthHeightTit'>高度</div>
                                <q-input outlined v-model="defaultData.height" :dense="true" label="高度" />
                            </div>
                        </div>
                            
                        <!-- </div> -->

                        <div>
                            <el-button type="primary" size="small" round plain @click="generateCode">生成二维码</el-button>
                            <el-button :disabled='imgsrc.length == 0' type="primary" size="small" round plain @click="downCode">下载二维码</el-button>
                        </div>
                    </div>
                </q-card-section>
            </q-card>

            <div class="QRcodeBox" :style='`max-height: ${maxHeight}px;overflow: auto;`'>
                <div class='imgbox' :style='`width: ${qrCodewidth}px;height:  ${qrCodeheight}px;`'>
                    <img v-if="imgsrc.length" id="codeImg" :src="imgsrc" alt="">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { getprovince,getcity,gethosplist,getdepart,getcombo,getattendingDoctor,getprovinceAndCity } from '@/api/components/checkcitys.js'; // 获取城市接口
import { makeDebounce } from "@/utils/debounce.js";  
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import QRCode from 'qrcode'  // 生成二维码插件

import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import selectd from '@/components/Order/select/departsselect.vue' // 科室下拉框组件
import comboselect from '@/components/Order/select/comboselect.vue' // 套餐下拉框组件

export default {
    data() {
        return {
            // 省份到套餐,主治医师总数据
            defaultData:{
                province:[],
                city:[],
                hosp:[],
                depart:[],
                combo:[],

                provinceId:'',
                cityId:'',
                hospId:'',
                departId:'',
                comboId:'',

                attendingDoctor: false, //主管医师
                surgeryDate: false, //手术时间
                hospNumber: false, //住院号
                discountCode: false, //优惠码

                width:230,
                height:230,
            },

            imgsrc:'',
            qrCodewidth:'',
            qrCodeheight:'',
            maxHeight: 200,
            checkAll: false,
            isIndeterminate: false
        }
    },
    created(){
        this.getprovinceList()
    },
    components:{
        selectp, // 省份下拉框组件,
        selectc, //城市下拉框
        selecth, // 医院下拉框
        selectd, // 科室下拉框
        comboselect, //套餐下拉框
    },
    computed:{
    },
    methods:{
        //打开弹窗获取省份信息
        async getprovinceList(){
            this.defaultData.province = await getprovince()

            let height = document.getElementById('defaultCondition').offsetHeight + 75
            this.maxHeight = getHeight('Qcode',height)
        },
        // 省份变化时获取城市列表
        async provincechange(){
            this.defaultData.city=[]
            this.defaultData.cityId =''
            this.defaultData.hosp=[]
            this.defaultData.hospId =''
            this.defaultData.depart=[]
            this.defaultData.departId =''
            this.defaultData.combo=[]
            this.defaultData.comboId =''

            this.defaultData.city = await getcity(this.defaultData.provinceId.id)
        },
        // 城市变化时获取医院列表
        async citychange(){
            // console.log(this.defaultData.provinceId.id);只有这样才能获取到id值
            this.defaultData.hosp=[]
            this.defaultData.hospId =''
            this.defaultData.depart=[]
            this.defaultData.departId =''
            this.defaultData.combo=[]
            this.defaultData.comboId =''

            this.defaultData.hosp = await gethosplist(this.defaultData.cityId.id)
        },
        // 医院发生变化时
        async hospchange(){
            this.defaultData.depart=[]
            this.defaultData.departId =''
            this.defaultData.combo=[]
            this.defaultData.comboId =''

            this.defaultData.depart = await getdepart(this.defaultData.hospId.id)
            this.defaultData.combo = await getcombo(this.defaultData.hospId.id,this.defaultData.cityId.id)
        },

        // 生成二维码
        generateCode:makeDebounce(function(evt){
            let target = evt.target;
            if(target.nodeName == "SPAN"){
                target = evt.target.parentNode;
            }
            target.blur()

            let height = document.getElementById('defaultCondition').offsetHeight + 75
            this.maxHeight = getHeight('Qcode',height)

            let provinceId = this.defaultData.provinceId.id
            let cityId = this.defaultData.cityId.id
            let hospId = this.defaultData.hospId.id
            let departId = this.defaultData.departId.id
            let comboId = this.defaultData.comboId.id

            let attendingDoctor = this.defaultData.attendingDoctor
            let surgeryDate = this.defaultData.surgeryDate
            let hospNumber = this.defaultData.hospNumber
            let discountCode = this.defaultData.discountCode

            let object = {
                provinceId,
                cityId,
                hospId,
                departId,
                comboId,
                attendingDoctor,
                surgeryDate,
                hospNumber,
                discountCode,
            }

            let defaultObject = JSON.stringify(object)

            console.log(defaultObject);

            this.qrCodewidth = (this.defaultData.width && this.defaultData.width <= 500 ) ? this.defaultData.width : 230 
            this.qrCodeheight = (this.defaultData.height && this.defaultData.height <= 500 ) ? this.defaultData.height : 230

            let defaultUrl = `${window.origin}/mobile/#/wechat/wechataddorder?defaultData=${defaultObject}`
            // let defaultUrl = `https://aiforcare.com/mobile/#/wechat/wechataddorder?defaultData=${defaultObject}`

            // let defaultUrl = `http://leic123.natapp1.cc/mobile/#/wechat/wechataddorder?defaultData=${defaultObject}` https://aiforcare.com/

            QRCode.toDataURL(defaultUrl,{ width: this.qrCodewidth, height: this.qrCodeheight,margin:1 }, (err, url)=> {
                this.imgsrc = url;
            })
            console.log(this.maxHeight);
            console.log(window.origin);
            console.log(window);
            console.log(defaultUrl);
        },300),

        // 下载二维码
        downCode:makeDebounce(function(evt){
            let target = evt.target;
            if(target.nodeName == "SPAN"){
                target = evt.target.parentNode;
            }
            target.blur()

            let provinceName = this.defaultData.provinceId.provinceName ? this.defaultData.provinceId.provinceName : ''
            let cityName = this.defaultData.cityId.cityName ? this.defaultData.cityId.cityName : ''
            let hospName = this.defaultData.hospId.hospName ? this.defaultData.hospId.hospName : ''
            let departName = this.defaultData.departId.departName ? this.defaultData.departId.departName : ''
            let comboName = this.defaultData.comboId.comboName ? this.defaultData.comboId.comboName : ''

            var img = document.getElementById('codeImg');
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                let context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                let url = canvas.toDataURL("image/jpg"); //得到图片的base64编码数据
                let a = document.createElement("a"); // 生成一个a元素
                let event = new MouseEvent("click"); // 创建一个单击事件
                a.download = `${provinceName} ${cityName} ${hospName} ${departName} ${comboName}`; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };

	        image.src = img.src;
        },300),

        handleCheckAllChange(v){
            this.isIndeterminate = false
            if(v){
                this.defaultData.attendingDoctor = true
                this.defaultData.surgeryDate = true
                this.defaultData.hospNumber = true
                this.defaultData.discountCode = true
            }else{
                this.defaultData.attendingDoctor = false
                this.defaultData.surgeryDate = false
                this.defaultData.hospNumber = false
                this.defaultData.discountCode = false
            }
        },
        checkboxChange(v){
            if(
                this.defaultData.attendingDoctor &&
                this.defaultData.surgeryDate &&
                this.defaultData.hospNumber &&
                this.defaultData.discountCode
            ){
                this.isIndeterminate = false
                this.checkAll = true
            }else if(
                !this.defaultData.attendingDoctor &&
                !this.defaultData.surgeryDate &&
                !this.defaultData.hospNumber &&
                !this.defaultData.discountCode
            ){
                this.isIndeterminate = false
                this.checkAll = false
            }else{
                this.isIndeterminate = true
                this.checkAll = false
            }
        },
        
    },
}
</script>
<style lang="less" scoped>
#Qcode{
    height: 100%;
    .conditionBox{
        .defaultBox{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 16px 16px 0;
            .tit{
                font-size: 16px;
                padding: 10px 10px;
            }
            .main{
                display: flex;
                width: 100%;
                .add{
                    width: 20%;
                    padding: 0 10px;
                }
            }
        }

        .optionalBox{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 16px;
            .tit{
                font-size: 16px;
                padding: 10px 10px;
            }
            .main{
                width: 100%;
                padding: 0 10px;
                .add{
                    width: 20%;
                    padding: 0 10px;
                }
            }
        }

        .attribute{
            display: flex;
            flex-direction: column;
            width: 100%;
            .tit{
                padding: 10px 10px;
                font-size: 16px;
                .Tips{
                    font-size: 12px;
                    color: #333;
                }
            }
            .main{
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 0 10px;
                .mainBox{
                    display: flex;
                    .widthHeight{
                        display: flex;
                        align-items: center;
                        margin-right: 10px;
                        .widthHeightTit{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .QRcodeBox{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 10px 0;
            .imgbox{
                // min-width: 230px;
                // min-height: 230px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

</style>
